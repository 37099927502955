import { default as pages_47_91_46_46_46slug_93_46vueMeta } from "/codebuild/output/src1939931646/src/anchorage/apps/frontend/pages/[...slug].vue?macro=true";
export default [
  {
    name: pages_47_91_46_46_46slug_93_46vueMeta?.name ?? "slug",
    path: pages_47_91_46_46_46slug_93_46vueMeta?.path ?? "/:slug(.*)*",
    children: [],
    meta: pages_47_91_46_46_46slug_93_46vueMeta,
    alias: pages_47_91_46_46_46slug_93_46vueMeta?.alias || [],
    redirect: pages_47_91_46_46_46slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1939931646/src/anchorage/apps/frontend/pages/[...slug].vue").then(m => m.default || m)
  }
]